import { Fragment } from 'react';
import { Button, styled } from 'shamrock-clover-ui';
import { useToggleBookItNowFeatureFlag } from '../hooks/useFeatureFlag';

const StyledButton = styled(Button)`
  border-radius: 20px;
  font-family: proxima-nova;
  pointer-events: none;
  svg {
    fill: currentColor;
  }
  text-transform: capitalize;
`;

const BookItNowToggleButton = () => {
  const { isBookItNowToggleFeatureEnabled = false } =
    useToggleBookItNowFeatureFlag();

  return (
    <Fragment>
      <StyledButton
        height={'36px'}
        buttonStyle={isBookItNowToggleFeatureEnabled ? 'solid' : 'outlined'}
      >
        Book It Now: {isBookItNowToggleFeatureEnabled ? 'On' : 'Off'}
      </StyledButton>
    </Fragment>
  );
};

export default BookItNowToggleButton;
