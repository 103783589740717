import http, { HttpMethods } from 'utils/httpUtils';
import {
  Carrier,
  EquipmentTypes,
  LaneAndCapacityForm,
  LaneObj,
  LanesAndCapacity,
  LoadDetailsType,
  RateType,
} from '../CapacityTypes';
import { LANE_SERVICE_URL } from 'utils/constants';
import { getLocationCoordinates } from './capacityUtils';

export const searchLanesCarrier = http.get<LanesAndCapacity[]>(
  `${LANE_SERVICE_URL as string}/carrier/lanes`,
);

export const carrierSearch = async (searchString: string) => {
  const queryString = `?q=${encodeURIComponent(searchString)}`;
  return http.get<Carrier[], undefined>(
    `${LANE_SERVICE_URL as string}/carriers${queryString}`,
  )(undefined);
};

export const getCarrierLanes = async (mcNumber: string): Promise<LaneObj[]> => {
  return http.get<LaneObj[], undefined>(
    `${LANE_SERVICE_URL as string}/carrier/${mcNumber}/lanes`,
  )(undefined);
};

export const addLaneAndCapacity = http.post<LaneObj, Partial<LaneObj>>(
  `${LANE_SERVICE_URL as string}/lane`,
);

export const editLaneAndCapacity = http.customHTTPMethodWithPath<
  string,
  HttpMethods,
  Partial<LaneObj>
>(`${LANE_SERVICE_URL as string}`);

export const buildLaneAndCapacityRequest = async (
  formObj: LaneAndCapacityForm,
  mcNumber?: string,
  mcleodCarrierId?: string,
  addCapacity?: boolean,
): Promise<Partial<LaneObj>> => {
  const {
    pickup,
    locationPickup,
    delivery,
    locationDelivery,
    trailers,
    truckQuantity,
    loadsPerTimeFrame,
    timeFrame,
    trailerType,
    rateAmount,
    selectedRateType,
    additionalServices,
    commentValue,
  } = formObj;

  let pickupCoordinates: number[] = [];
  let deliveryCoordinates: number[] = [];
  // check if pickupType is city/zip
  if (
    pickup.optionSelected?.option === 'City' &&
    locationPickup.optionSelected?.optionName
  ) {
    // if it is, use google maps api to get the coordinates for the city/zip
    pickupCoordinates = await getLocationCoordinates(
      locationPickup.optionSelected.optionName,
    );
  }

  // check if deliveryType is city/zip
  if (
    delivery.optionSelected?.option === 'City' &&
    locationDelivery.optionSelected?.optionName
  ) {
    // if it is, use google maps api to get the coordinates for the city/zip
    deliveryCoordinates = await getLocationCoordinates(
      locationDelivery.optionSelected.optionName,
    );
  }

  // get selected trailers
  const selectedTrailers: EquipmentTypes[] = (trailers.multiSelect || [])
    .filter((x) => x.checked)
    .map((option) => option.text as EquipmentTypes);

  const AddLaneRequestBody = {
    lane: {
      mcNumber,
      mcleodCarrierId,
      pickupType: pickup.optionSelected?.option as 'City' | 'State',
      ...(pickup.optionSelected?.option === 'City' && {
        pickupCity: {
          cityName: locationPickup.optionSelected?.optionName,
          coordinates: pickupCoordinates,
          type: 'Point',
        },
      }),
      ...(pickup.optionSelected?.option === 'State' && {
        pickupState: locationPickup.optionSelected?.optionName,
      }),
      deliveryType: delivery.optionSelected?.option as 'City' | 'State',
      ...(delivery.optionSelected?.option === 'City' && {
        deliveryCity: {
          cityName: locationDelivery.optionSelected?.optionName,
          coordinates: deliveryCoordinates,
          type: 'Point',
        },
      }),
      ...(delivery.optionSelected?.option === 'State' && {
        deliveryState: locationDelivery.optionSelected?.optionName,
      }),
      trailerTypes: selectedTrailers,
    },
    // if the form has capacity, add the capacity to the request body
    ...(addCapacity && {
      capacity: {
        trucks: parseInt(truckQuantity?.optionSelected?.optionName || '0'),
        loadsPerTimeFrame: parseInt(
          loadsPerTimeFrame?.optionSelected?.optionName || '0',
        ),
        frequencyTimeFrame: timeFrame?.optionSelected?.optionName || '',
        ...(additionalServices?.multiSelect &&
          additionalServices?.multiSelect?.filter((x) => x.checked).length >
            0 && {
            loadDetailServices: additionalServices.multiSelect
              .filter((x) => x.checked)
              .map((x) => x.text as LoadDetailsType),
          }),
        ...(commentValue?.optionSelected?.optionName && {
          loadDetailNotes: commentValue?.optionSelected?.optionName,
        }),
        rate: [
          {
            trailerType: trailerType?.optionSelected
              ?.optionName as EquipmentTypes,
            ...(rateAmount?.optionSelected?.optionName &&
              rateAmount.optionSelected.optionName.length > 1 && {
                amount:
                  (selectedRateType?.optionSelected?.optionName as RateType) ===
                  'Flat rate'
                    ? parseInt(
                        rateAmount.optionSelected.optionName.replace(
                          /[^0-9]/g,
                          '',
                        ),
                      )
                    : Number(
                        rateAmount.optionSelected.optionName.replace(
                          /[^0-9.]/g,
                          '',
                        ),
                      ),
              }),
            rateType: selectedRateType?.optionSelected?.optionName as RateType,
          },
        ],
      },
    }),
  };

  return AddLaneRequestBody;
};
