/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';
import { DrawerContentNavigation } from './DrawerStyles';
import {
  CarrierProfileTab,
  LanesAndCapacity,
} from 'features/carrierCapacity/CapacityTypes';
import * as FirebaseUtils from 'utils/firebaseUtils';

const DrawerSubHeader: FC<{
  drawerTabClicked: CarrierProfileTab;
  setTabClicked: (tab: CarrierProfileTab) => void;
  lanesAndCapacity: LanesAndCapacity[];
  carrierIndexTaken: number | null;
}> = ({
  drawerTabClicked,
  setTabClicked,
  lanesAndCapacity,
  carrierIndexTaken,
}) => {
  const handleDetailsClicked = () => {
    setTabClicked('Details');
  };

  const handleLoadHistoryClicked = () => {
    try {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.CLICK,
        FirebaseUtils.FirebaseModules.CARRIER_CAPACITY,
        FirebaseUtils.FirebasePages.CARRIER_PROFILE,
        {
          description: 'View Load History',
          mcleodCarrierId:
            lanesAndCapacity[carrierIndexTaken!].carrier.mcleodCarrierId,
          MC: lanesAndCapacity[carrierIndexTaken!].carrier.mcNumber,
        },
      );
    } catch (e) {
      console.log('Error logging firebase event');
    }
    setTabClicked('Load History');
  };

  const tabs = [
    {
      title: 'Details',
      onClick: handleDetailsClicked,
    },
    {
      title: 'Load History',
      onClick: handleLoadHistoryClicked,
    },
  ];

  return (
    <DrawerContentNavigation
      tabs={tabs}
      separatorWidth={0}
      drawerTabClicked={drawerTabClicked}
    />
  );
};

export default DrawerSubHeader;
