import { Button, Icon, styled } from 'shamrock-clover-ui';
import {
  Card,
  CardContent,
  CardHeaderTitles,
  CardsCapacityContainer,
  CustomButton,
  CardHeader,
} from './DetailsTableStyles';
import EditIcon from 'components/Icon';
import { FC, useContext, useEffect, useState } from 'react';
import {
  DetailsTableProps,
  LaneObj,
} from 'features/carrierCapacity/CapacityTypes';
import {
  capitalizeWords,
  parseRateAmount,
  truncateString,
} from 'features/carrierCapacity/utils/capacityUtils';
import LanesSort from './LanesSort';
import { formatDate } from 'utils/dateUtils';
import { CarrierCapacityContext } from 'features/carrierCapacity/context/CarrierCapacityContext';
import LanesFilter from './LanesFilter';
import * as FirebaseUtils from 'utils/firebaseUtils';

const columnsHeader = [
  'Pickup',
  'Delivery',
  'Equipment',
  'Capacity',
  'History',
  'Edit',
];

const EditPencilIcon = styled(EditIcon)`
  cursor: pointer;
`;

const CarrierEmptyState = styled.div`
  text-align: center;
  color: #666666;
  font-weight: 400;
  font-family: proxima-nova;
  .primary-text {
    font-size: 24px;
  }
  .secondary-text {
    font-size: 16px;
  }
  button {
    color: #0091ea;
    text-decoration: none;
    cursor: pointer;
    border: none;
    padding: 0;
    font-family: proxima-nova;
  }
`;

const StyledDetailsAndSortContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
`;

const StyledLanesCount = styled.div`
  width: 310px;
`;

const ADMIN_EMAILS = [
  'ryantrans.com',
  'ryanrts.com',
  'shamrocktradingcorp.com',
];
const MAX_CHAR_LENGTH = 22;

const getHistoryType = (lane: LaneObj) => {
  const emailDomain = lane.updatedBy.split('@')[1];
  return ADMIN_EMAILS.includes(emailDomain) ? 'Ryan Trans Admin' : 'Carrier';
};

const DetailsTable: FC<DetailsTableProps> = ({
  actionHandler,
  editHandler,
}) => {
  const { lanesAndCapacity, carrierIndexTaken, selectedFilterMethod } =
    useContext(CarrierCapacityContext);
  const lanes = carrierIndexTaken
    ? lanesAndCapacity[carrierIndexTaken].lanes
    : lanesAndCapacity[0].lanes;
  const [filteredLanes, setFilteredLanes] = useState<LaneObj[]>([]);

  // we need to render the table when the filter method or the lanesAndCapacity changes
  useEffect(() => {
    if (selectedFilterMethod === 'All') {
      setFilteredLanes(lanes);
    }
    if (selectedFilterMethod === 'Carrier (RTS Pro)') {
      setFilteredLanes(
        lanes.filter((lane) => 'Carrier' === getHistoryType(lane)),
      );
    }
    if (selectedFilterMethod === 'Ryan Trans Admin') {
      setFilteredLanes(
        lanes.filter((lane) => 'Ryan Trans Admin' === getHistoryType(lane)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterMethod, lanesAndCapacity]);

  // we need to find the index of the click lane from the lanes object to pass to the editHandler
  const onPencilClick = (laneId: string) => {
    const index = lanes.findIndex((lane) => lane._id === laneId);
    try {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.CLICK,
        FirebaseUtils.FirebaseModules.CARRIER_CAPACITY,
        FirebaseUtils.FirebasePages.CARRIER_PROFILE,
        {
          description: 'Open Editing Lane Form',
          mcleodCarrierId: carrierIndexTaken
            ? lanesAndCapacity[carrierIndexTaken].carrier.mcleodCarrierId
            : '',
          MC: carrierIndexTaken
            ? lanesAndCapacity[carrierIndexTaken].carrier.mcNumber
            : '',
        },
      );
    } catch (error) {
      console.log('Error logging firebase event');
    }

    if (editHandler) {
      editHandler(index);
    }
  };

  const handleAddLane = () => {
    try {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.CLICK,
        FirebaseUtils.FirebaseModules.CARRIER_CAPACITY,
        FirebaseUtils.FirebasePages.CARRIER_PROFILE,
        {
          description: 'Open Add Lane Form',
          mcleodCarrierId: carrierIndexTaken
            ? lanesAndCapacity[carrierIndexTaken].carrier.mcleodCarrierId
            : '',
          MC: carrierIndexTaken
            ? lanesAndCapacity[carrierIndexTaken].carrier.mcNumber
            : '',
        },
      );
    } catch (error) {
      console.log('Error logging firebase event');
    }

    if (actionHandler) {
      actionHandler();
    }
  };

  const handleAddCapacity = (index: number, source?: 'addCapacity') => {
    try {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.CLICK,
        FirebaseUtils.FirebaseModules.CARRIER_CAPACITY,
        FirebaseUtils.FirebasePages.CARRIER_PROFILE,
        {
          description: 'Open Capacity Form',
          mcleodCarrierId: carrierIndexTaken
            ? lanesAndCapacity[carrierIndexTaken].carrier.mcleodCarrierId
            : '',
          MC: carrierIndexTaken
            ? lanesAndCapacity[carrierIndexTaken].carrier.mcNumber
            : '',
        },
      );
    } catch (error) {
      console.log('Error logging firebase event');
    }
    if (editHandler) {
      editHandler(index, source);
    }
  };

  return (
    <div style={{ padding: '20px 0px 16px 0px' }}>
      <CardsCapacityContainer>
        <CardHeader>
          <StyledDetailsAndSortContainer>
            <StyledLanesCount>
              <p>
                Lanes:{' '}
                <span>
                  {`Viewing ${filteredLanes.length} of ${lanes.length}`}
                </span>
              </p>
            </StyledLanesCount>
            <LanesSort />
            <LanesFilter />
          </StyledDetailsAndSortContainer>
          <div>
            <CustomButton
              buttonStyle="solid"
              color="blue"
              icon={<Icon icon="plus" color="white" size="16" />}
              onClick={handleAddLane}
            >
              Add Lane
            </CustomButton>
          </div>
        </CardHeader>
        <CardHeaderTitles>
          {columnsHeader.map((x) => (
            <span>{x}</span>
          ))}
        </CardHeaderTitles>
        {lanes.length === 0 && (
          <CarrierEmptyState>
            <p className="primary-text">
              No lanes associated with this carrier.
            </p>
            <p className="secondary-text">
              In contact with them?{' '}
              <button onClick={actionHandler}>Add lanes and capacity</button> to
              their profile.
            </p>
          </CarrierEmptyState>
        )}
        {filteredLanes.map((x, index) => {
          const trucksTimeFrameFrequencyText = `${x.capacity?.trucks} trucks, ${x.capacity?.loadsPerTimeFrame}/${x.capacity?.frequencyTimeFrame.toLowerCase()}`;

          // Rate is required, but rate amount is not.
          const formatRateAmount =
            x.capacity?.rate !== undefined &&
            x.capacity.rate[0]?.amount &&
            parseRateAmount(
              x.capacity.rate[0].amount.toString(),
              capitalizeWords(x.capacity.rate[0].rateType),
            );
          const rateAmountText = formatRateAmount
            ? `$${formatRateAmount}`
            : '$-';

          const rateText =
            x.capacity?.rate !== undefined
              ? x.capacity.rate[0].rateType.toLowerCase()
              : null;
          const loadDetailsText =
            x.capacity &&
            x.capacity.loadDetailServices &&
            x.capacity.loadDetailServices.join(', ');

          return (
            <Card>
              <CardContent>
                <span style={{ fontWeight: 600 }}>
                  {x.pickupType === 'City'
                    ? x.pickupCity?.cityName
                    : x.pickupState}
                </span>
                <Icon icon="arrowRight" size="16" color="gray4" />
              </CardContent>
              <CardContent>
                <span style={{ fontWeight: 600 }}>
                  {x.deliveryType === 'City'
                    ? x.deliveryCity?.cityName
                    : x.deliveryState}
                </span>
              </CardContent>
              <CardContent>
                <span style={{ fontWeight: 600 }}>
                  {x.trailerTypes && x.trailerTypes.join(', ')}
                </span>
              </CardContent>

              {x?.capacity ? (
                <CardContent style={{ display: 'block' }}>
                  <span style={{ fontWeight: 500 }}>
                    {trucksTimeFrameFrequencyText +
                      ', ' +
                      x.capacity.rate[0].trailerType +
                      ', ' +
                      rateAmountText +
                      ' ' +
                      rateText}
                    {loadDetailsText && ', ' + loadDetailsText}
                  </span>
                  <br />
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: '12px',
                      color: '#333333',
                    }}
                  >
                    Notes: {x.capacity.loadDetailNotes}
                  </span>
                </CardContent>
              ) : (
                <CardContent>
                  <Button
                    icon={<Icon icon="plus" color="blue" size="14" />}
                    buttonStyle="text"
                    onClick={() => handleAddCapacity(index, 'addCapacity')}
                  >
                    Add Capacity
                  </Button>
                </CardContent>
              )}

              <CardContent
                style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'start',
                }}
              >
                <span style={{ fontWeight: 600 }}>
                  {x.isSuggested ? 'Suggested Lane' : getHistoryType(x)}
                  {x.deletedBy && (
                    <>
                      <span> - </span>
                      <span style={{ color: '#EF3824' }}>Deleted</span>
                    </>
                  )}
                </span>
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: '12px',
                    color: '#333333',
                  }}
                >
                  {x.isSuggested
                    ? 'suggestedlane@ryantrans.com'
                    : truncateString(x.updatedBy, MAX_CHAR_LENGTH)}
                </span>
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: '12px',
                    color: '#333333',
                  }}
                >
                  {formatDate(x.updatedAt)}
                </span>
              </CardContent>
              <CardContent>
                <EditPencilIcon
                  name="EditPencil"
                  onHoverName="EditPencilHover"
                  alt="EditIcon"
                  onClick={() => onPencilClick(x._id)}
                />
              </CardContent>
            </Card>
          );
        })}
      </CardsCapacityContainer>
    </div>
  );
};

export default DetailsTable;
