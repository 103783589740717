import { FC } from 'react';
import { EditActionButtonsProps } from 'features/carrierCapacity/CapacityTypes';
import { Button } from 'shamrock-clover-ui';
import * as FirebaseUtils from 'utils/firebaseUtils';

export const ActionButtons: FC<EditActionButtonsProps> = ({
  disable,
  companyInfo,
  handleOnCancel,
  handleOnClick,
}) => {
  const handleAddLane = () => {
    try {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.CLICK,
        FirebaseUtils.FirebaseModules.CARRIER_CAPACITY,
        FirebaseUtils.FirebasePages.ADDING_LANE,
        {
          description: 'Add Lane/Capacity for Carrier',
          mcleodCarrierId: companyInfo?.carrier?.mcleodCarrierId,
          MC: companyInfo?.carrier?.mcNumber,
        },
      );
    } catch (error) {
      console.log('Error logging firebase event');
    }

    handleOnClick(companyInfo?.carrier!);
  };

  return (
    <div style={{ display: 'flex', gap: 20 }}>
      <Button buttonStyle="outlined" onClick={handleOnCancel}>
        Cancel
      </Button>
      <Button
        buttonStyle="solid"
        color="green"
        disabled={disable}
        onClick={handleAddLane}
      >
        Add Lane
      </Button>
    </div>
  );
};

export const EditActionButtons: FC<EditActionButtonsProps> = ({
  disable,
  companyInfo,
  handleOnShowDialog,
  handleOnCancel,
  handleOnClick,
}) => {
  return (
    <div style={{ display: 'flex', gap: 20 }}>
      <Button buttonStyle="outlined" onClick={handleOnCancel}>
        Cancel
      </Button>
      <Button buttonStyle="outlined" onClick={handleOnShowDialog}>
        Delete lane
      </Button>
      <Button
        buttonStyle="solid"
        color="green"
        disabled={disable}
        onClick={() => handleOnClick(companyInfo?.carrier!)}
      >
        Save Changes
      </Button>
    </div>
  );
};
